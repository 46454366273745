import React, { ReactElement, useState } from 'react';

import UserContext from './topicContext';

interface UserProviderProps {
    user: string;
    children: ReactElement | ReactElement[];
}

const UserProvider: React.FC = ({ children }: UserProviderProps): ReactElement => {
    const [user, setUser] = useState<string>();
    const [group, setGroup] = useState<number>();

    return (
        <UserContext.Provider value={{ user, setUser, group, setGroup }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
