import { Pane } from '@ti/ui-lib';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ErrorPage } from '../ErrorPage';
import { Home } from '../Home';
import { SimulatorPage } from '../SimulatorPage';

const PageRouter: React.FC = (): React.ReactElement => {
    return (
        <Pane space="p0" flex flexGrow col alignItems="stretch">
            <Switch>
                <Route exact path="/simulator" component={SimulatorPage} />
                <Route exact path="/error" component={ErrorPage} />
                <Route path="/:sessionId?" component={Home} />
            </Switch>
        </Pane>
    );
};

export default PageRouter;
