import { Pane, Tooltip, Button } from '@ti/ui-lib';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

const Sidebar: React.FC = (): ReactElement => {
    const { pathname } = useLocation();

    return (
        <Pane className="sidebar" light col space="p2" border="right" minWidth={50}>
            <Tooltip content="Simulator" placement="right">
                <Button
                    primary
                    large
                    inverted
                    rounded
                    active={pathname === '/'}
                    space="px0"
                    width={32}
                >
                    SI
                </Button>
            </Tooltip>
        </Pane>
    );
};

export default Sidebar;
