import { EmptyState, Pane } from '@ti/ui-lib';
import React, { ReactElement } from 'react';

const ErrorPage: React.FC = (): ReactElement => {
    return (
        <Pane flex flexGrow col>
            <EmptyState
                title="Bad URL"
                description="Please use the URL provided in your email."
                icon="error"
            ></EmptyState>
        </Pane>
    );
};

export default ErrorPage;
