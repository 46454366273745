import { ApolloProvider } from '@apollo/client';
import { Box } from '@ti/ui-lib';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.scss';
import Header from './components/Header/Header';
import { PageRouter } from './components/PageRouter';
import { Sidebar } from './components/Sidebar';
import UserProvider from './context/UserProvider';
import { client } from './util/apollo-config';

const App: React.FC = (): React.ReactElement => {
    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Box flex col pos="fullScreen">
                    <Header />
                    <Box overflow="auto" flex flexGrow pos="relative">
                        <Sidebar />
                        <UserProvider>
                            <PageRouter />
                        </UserProvider>
                    </Box>
                </Box>
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default App;
