import { Box, Button, Heading, Icon, Pane, Popover, Tag, Text } from '@ti/ui-lib';
import ClassName from 'classnames';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import UserContext from '../../context/topicContext';
import {
    useConfirmHeartbeatMutation,
    useHeartbeatSentSubscription,
    useHeadlineSentSubscription,
    useAnswerMutation,
} from '../../queries/autogenerate/hooks';
import { Headline, SessionState, Side } from '../../queries/autogenerate/types';
import './SimulatorPage.scss';

const SimulatorPage: React.FC = (): ReactElement => {
    const { user, group } = useContext(UserContext);
    const history = useHistory();
    const [countdown, setCountdown] = useState(0);
    const [headline, setHeadline] = useState<Headline>();
    const [sessionId, setSessionId] = useState<number>();
    const [sideBackground, setSideBackground] = useState<string>();
    const [answered, setAnswered] = useState(false);
    const [delayStart, setDelayStart] = useState<Date>();
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        if (!user || !group) {
            history.push('/');
        }
    }, [user]);

    const { data: heartbeatData } = useHeartbeatSentSubscription({
        variables: {
            heartbeatSubscriptionInput: {
                userKey: user,
                groupId: group,
            },
        },
        skip: !user || !group,
    });

    const [confirmHeartbeat] = useConfirmHeartbeatMutation();

    const { data: headlineData } = useHeadlineSentSubscription({
        variables: {
            groupId: group,
        },
        skip: !group,
    });

    const [answer] = useAnswerMutation();

    const keyPressHandler = useCallback(
        async (event: KeyboardEvent) => {
            let side;
            if (event.key === 'm') {
                side = Side.Bad;
            } else if (event.key === 'z') {
                side = Side.Good;
            } else {
                side = Side.Mistake;
            }

            if (side && headline && !answered) {
                try {
                    setSideBackground(side);
                    setAnswered(true);
                    const now = new Date();
                    await answer({
                        variables: {
                            answerInput: {
                                headlineId: headline.id,
                                groupId: group,
                                userKey: user,
                                sessionId: sessionId,
                                side,
                                delay: now.getTime() - delayStart.getTime(),
                            },
                        },
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        },
        [headline, answered, sideBackground],
    );

    useEffect(() => {
        window.addEventListener('keydown', keyPressHandler);

        return () => {
            window.removeEventListener('keydown', keyPressHandler);
        };
    }, [keyPressHandler]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (headlineData?.headlineSent?.content) {
            if (headlineData.headlineSent.content.__typename === 'Countdown') {
                setSessionId(headlineData.headlineSent.sessionId);
                if (headlineData.headlineSent.state === SessionState.Start) {
                    let countdown = headlineData.headlineSent.content.delay;
                    setCountdown(countdown);

                    interval = setInterval(() => {
                        countdown--;
                        setCountdown(countdown);
                    }, 1000);
                    setIsLive(true);
                } else if (headlineData.headlineSent.state === SessionState.End) {
                    setHeadline(undefined);
                    setIsLive(false);
                }
            } else if (headlineData.headlineSent.content.__typename === 'Headline') {
                setSideBackground(undefined);
                setAnswered(false);
                setHeadline(headlineData.headlineSent.content);
                setDelayStart(new Date());
                setIsLive(true);
            }
        }

        return () => {
            clearInterval(interval);
        };
    }, [headlineData]);

    useEffect(() => {
        if (heartbeatData) {
            confirmHeartbeat({
                variables: {
                    heartbeatConfirmationInput: {
                        userKey: user,
                        groupId: group,
                        timestamp: new Date().getTime(),
                    },
                },
            });
        }
    }, [heartbeatData]);

    const backgroundClasses = ClassName({
        'headline-background': sideBackground === Side.Good || sideBackground === Side.Bad,
    });

    return (
        <Pane flex flexGrow col>
            <Box flex alignItems="center" justifyContent={isLive ? 'between' : 'start'}>
                <Box flex alignItems="center">
                    <Heading size={5}>Simulator</Heading>
                    <Popover
                        placement="right-end"
                        content={
                            <Box width={500}>
                                <Heading>Instructions</Heading>
                                <Text>
                                    <p>
                                        Once the simulation is started by your interviewer, a
                                        countdown will appear on your screen. Once the countdown
                                        reaches 0, headlines will start to appear at random
                                        intervals and disappear from your screen after a short
                                        delay.
                                    </p>
                                </Text>
                            </Box>
                        }
                    >
                        <Button minimal icon="help" />
                    </Popover>
                </Box>
                {isLive && (
                    <Tag intent="danger">
                        <Icon icon="record" space="mr1" color="danger" />
                        Interview in progress
                    </Tag>
                )}
            </Box>
            {countdown >= 1 && (
                <Box flex flexGrow justifyContent="center" alignItems="center">
                    <Text size={5}>{countdown}</Text>
                </Box>
            )}
            {countdown < 1 && headline && (
                <Box flex flexGrow justifyContent="center" alignItems="center" space="p2">
                    <Box className={backgroundClasses} space="p2">
                        <Text size={5}>{headline.text}</Text>
                    </Box>
                </Box>
            )}
        </Pane>
    );
};

export default SimulatorPage;
