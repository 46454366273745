export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
};

export type Answer = {
    __typename?: 'Answer';
    delay?: Maybe<Scalars['Float']>;
    groupId: Scalars['Int'];
    headlineId?: Maybe<Scalars['Int']>;
    sessionId?: Maybe<Scalars['Int']>;
    side?: Maybe<Scalars['String']>;
    user?: Maybe<User>;
};

export type AnswerInput = {
    delay?: InputMaybe<Scalars['Float']>;
    groupId: Scalars['Int'];
    headlineId: Scalars['Int'];
    sessionId: Scalars['Int'];
    side?: InputMaybe<Side>;
    userKey: Scalars['String'];
};

export enum ContentType {
    Countdown = 'Countdown',
    Headline = 'HEADLINE',
}

export type Countdown = {
    __typename?: 'Countdown';
    delay?: Maybe<Scalars['Int']>;
};

export type Group = {
    __typename?: 'Group';
    creationDate?: Maybe<Scalars['Date']>;
    generatedLink: Scalars['String'];
    id: Scalars['Int'];
    interviewDate: Scalars['Date'];
    lastUpdated?: Maybe<Scalars['Date']>;
    lastUpdatedBy?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    users?: Maybe<Array<Maybe<User>>>;
};

export type GroupAddInput = {
    interviewDate: Scalars['Date'];
    name: Scalars['String'];
    users: Array<UserInput>;
};

export type GroupUpdateInput = {
    id: Scalars['Int'];
    interviewDate: Scalars['Date'];
    name: Scalars['String'];
    users: Array<UserInput>;
};

export type Headline = {
    __typename?: 'Headline';
    id: Scalars['Int'];
    side?: Maybe<Side>;
    text?: Maybe<Scalars['String']>;
};

export type HeadlineInput = {
    side?: InputMaybe<Side>;
    text?: InputMaybe<Scalars['String']>;
};

export type Heartbeat = {
    __typename?: 'Heartbeat';
    groupId: Scalars['Int'];
    timestamp: Scalars['Date'];
};

export type HeartbeatConfirmationInput = {
    groupId: Scalars['Int'];
    timestamp: Scalars['Date'];
    userKey: Scalars['String'];
};

export type HeartbeatSubscriptionInput = {
    groupId: Scalars['Int'];
    userKey: Scalars['String'];
};

export type IdentityConfirmInput = {
    hash: Scalars['String'];
    userKey: Scalars['String'];
};

export type IdentityConfirmOutput = {
    __typename?: 'IdentityConfirmOutput';
    confirmed: Scalars['Boolean'];
    groupId?: Maybe<Scalars['Int']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    addGroup: Group;
    addSimulation?: Maybe<Simulation>;
    answer: Answer;
    confirmHeartbeat: Scalars['String'];
    confirmIdentity: IdentityConfirmOutput;
    startSession: Session;
    stopSession: Session;
    updateGroup: Group;
    updateSimulation?: Maybe<Simulation>;
};

export type MutationAddGroupArgs = {
    groupAddInput: GroupAddInput;
};

export type MutationAddSimulationArgs = {
    simulationAddInput: SimulationAddInput;
};

export type MutationAnswerArgs = {
    answerInput: AnswerInput;
};

export type MutationConfirmHeartbeatArgs = {
    heartbeatConfirmationInput: HeartbeatConfirmationInput;
};

export type MutationConfirmIdentityArgs = {
    identityConfirmInput: IdentityConfirmInput;
};

export type MutationStartSessionArgs = {
    sessionStartInput: SessionStartInput;
};

export type MutationStopSessionArgs = {
    groupId: Scalars['Int'];
    sessionId: Scalars['Int'];
};

export type MutationUpdateGroupArgs = {
    groupUpdateInput: GroupUpdateInput;
};

export type MutationUpdateSimulationArgs = {
    simulationUpdateInput: SimulationUpdateInput;
};

export type OnlineStatus = {
    __typename?: 'OnlineStatus';
    groupId: Scalars['Int'];
    users: Array<Maybe<Scalars['String']>>;
};

export type Query = {
    __typename?: 'Query';
    answers: Array<Maybe<Answer>>;
    group?: Maybe<Group>;
    groups?: Maybe<Array<Maybe<Group>>>;
    sessionStatus: Session;
    sessions?: Maybe<Array<Maybe<Session>>>;
    simulation: Simulation;
    simulations?: Maybe<Array<Maybe<Simulation>>>;
};

export type QueryAnswersArgs = {
    sessionId: Scalars['Int'];
};

export type QueryGroupArgs = {
    groupId: Scalars['Int'];
};

export type QuerySessionStatusArgs = {
    sessionId: Scalars['Int'];
};

export type QuerySimulationArgs = {
    id: Scalars['Int'];
};

export type Session = {
    __typename?: 'Session';
    creationDate?: Maybe<Scalars['Date']>;
    groupId?: Maybe<Scalars['Int']>;
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    simulationId?: Maybe<Scalars['Int']>;
    status?: Maybe<SessionState>;
};

export type SessionStartInput = {
    countdown: Scalars['Int'];
    groupId: Scalars['Int'];
    maxDelay: Scalars['Int'];
    minDelay: Scalars['Int'];
    name: Scalars['String'];
    simulationId: Scalars['Int'];
};

export enum SessionState {
    End = 'END',
    Start = 'START',
}

export enum Side {
    Bad = 'BAD',
    Good = 'GOOD',
    Mistake = 'MISTAKE',
}

export type Signal = {
    __typename?: 'Signal';
    content: SignalContent;
    groupId: Scalars['Int'];
    sessionId: Scalars['Int'];
    state: SessionState;
};

export type SignalContent = Countdown | Headline;

export type Simulation = {
    __typename?: 'Simulation';
    creationDate?: Maybe<Scalars['Date']>;
    headlines?: Maybe<Array<Maybe<Headline>>>;
    id: Scalars['Int'];
    lastUpdated?: Maybe<Scalars['Date']>;
    lastUpdatedBy?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type SimulationAddInput = {
    headlines: Array<InputMaybe<HeadlineInput>>;
    name: Scalars['String'];
};

export type SimulationUpdateInput = {
    headlines: Array<InputMaybe<HeadlineInput>>;
    id: Scalars['Int'];
    name: Scalars['String'];
};

export type Subscription = {
    __typename?: 'Subscription';
    headlineSent: Signal;
    heartbeatSent?: Maybe<Heartbeat>;
    heartbeatsUpdated: OnlineStatus;
};

export type SubscriptionHeadlineSentArgs = {
    groupId: Scalars['Int'];
};

export type SubscriptionHeartbeatSentArgs = {
    heartbeatSubscriptionInput: HeartbeatSubscriptionInput;
};

export type SubscriptionHeartbeatsUpdatedArgs = {
    groupId: Scalars['Int'];
};

export enum Subscription_Labels {
    Heartbeat = 'Heartbeat',
    OnlineUsers = 'Online_Users',
    Signal = 'Signal',
}

export type User = {
    __typename?: 'User';
    key: Scalars['String'];
};

export type UserInput = {
    key: Scalars['String'];
};
