import { Pane, Box, Tag } from '@ti/ui-lib';
import React from 'react';

import packageJson from '../../../../package.json';
import logo from '../../assets/images/DRW_logo_RGB.png';

import './Header.scss';

const Header: React.FC = (): React.ReactElement => {
    return (
        <Pane
            light
            border="bottom"
            space="py1 px2"
            flex
            alignItems="center"
            justifyContent="between"
            minHeight={41}
            maxHeight={41}
        >
            <Box flex alignItems="center">
                <Box height={30} className="header logo" flex alignItems="center">
                    <img src={logo} />
                </Box>
                <Tag space="ml2">{packageJson.version}</Tag>
            </Box>
        </Pane>
    );
};

export default Header;
