import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AnswerDocument = gql`
    mutation Answer($answerInput: AnswerInput!) {
        answer(answerInput: $answerInput) {
            user {
                key
            }
            side
            sessionId
            delay
            groupId
            headlineId
        }
    }
`;
export type AnswerMutationFn = Apollo.MutationFunction<
    Types.AnswerMutation,
    Types.AnswerMutationVariables
>;

/**
 * __useAnswerMutation__
 *
 * To run a mutation, you first call `useAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerMutation, { data, loading, error }] = useAnswerMutation({
 *   variables: {
 *      answerInput: // value for 'answerInput'
 *   },
 * });
 */
export function useAnswerMutation(
    baseOptions?: Apollo.MutationHookOptions<Types.AnswerMutation, Types.AnswerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<Types.AnswerMutation, Types.AnswerMutationVariables>(
        AnswerDocument,
        options,
    );
}
export type AnswerMutationHookResult = ReturnType<typeof useAnswerMutation>;
export type AnswerMutationResult = Apollo.MutationResult<Types.AnswerMutation>;
export type AnswerMutationOptions = Apollo.BaseMutationOptions<
    Types.AnswerMutation,
    Types.AnswerMutationVariables
>;
export const HeadlineSentDocument = gql`
    subscription HeadlineSent($groupId: Int!) {
        headlineSent(groupId: $groupId) {
            sessionId
            groupId
            state
            content {
                ... on Countdown {
                    delay
                }
                ... on Headline {
                    id
                    text
                    side
                }
            }
        }
    }
`;

/**
 * __useHeadlineSentSubscription__
 *
 * To run a query within a React component, call `useHeadlineSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeadlineSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeadlineSentSubscription({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useHeadlineSentSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        Types.HeadlineSentSubscription,
        Types.HeadlineSentSubscriptionVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.HeadlineSentSubscription,
        Types.HeadlineSentSubscriptionVariables
    >(HeadlineSentDocument, options);
}
export type HeadlineSentSubscriptionHookResult = ReturnType<typeof useHeadlineSentSubscription>;
export type HeadlineSentSubscriptionResult =
    Apollo.SubscriptionResult<Types.HeadlineSentSubscription>;
export const HeartbeatSentDocument = gql`
    subscription HeartbeatSent($heartbeatSubscriptionInput: HeartbeatSubscriptionInput!) {
        heartbeatSent(heartbeatSubscriptionInput: $heartbeatSubscriptionInput) {
            timestamp
            groupId
        }
    }
`;

/**
 * __useHeartbeatSentSubscription__
 *
 * To run a query within a React component, call `useHeartbeatSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeartbeatSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeartbeatSentSubscription({
 *   variables: {
 *      heartbeatSubscriptionInput: // value for 'heartbeatSubscriptionInput'
 *   },
 * });
 */
export function useHeartbeatSentSubscription(
    baseOptions: Apollo.SubscriptionHookOptions<
        Types.HeartbeatSentSubscription,
        Types.HeartbeatSentSubscriptionVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useSubscription<
        Types.HeartbeatSentSubscription,
        Types.HeartbeatSentSubscriptionVariables
    >(HeartbeatSentDocument, options);
}
export type HeartbeatSentSubscriptionHookResult = ReturnType<typeof useHeartbeatSentSubscription>;
export type HeartbeatSentSubscriptionResult =
    Apollo.SubscriptionResult<Types.HeartbeatSentSubscription>;
export const ConfirmHeartbeatDocument = gql`
    mutation ConfirmHeartbeat($heartbeatConfirmationInput: HeartbeatConfirmationInput!) {
        confirmHeartbeat(heartbeatConfirmationInput: $heartbeatConfirmationInput)
    }
`;
export type ConfirmHeartbeatMutationFn = Apollo.MutationFunction<
    Types.ConfirmHeartbeatMutation,
    Types.ConfirmHeartbeatMutationVariables
>;

/**
 * __useConfirmHeartbeatMutation__
 *
 * To run a mutation, you first call `useConfirmHeartbeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmHeartbeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmHeartbeatMutation, { data, loading, error }] = useConfirmHeartbeatMutation({
 *   variables: {
 *      heartbeatConfirmationInput: // value for 'heartbeatConfirmationInput'
 *   },
 * });
 */
export function useConfirmHeartbeatMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ConfirmHeartbeatMutation,
        Types.ConfirmHeartbeatMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ConfirmHeartbeatMutation,
        Types.ConfirmHeartbeatMutationVariables
    >(ConfirmHeartbeatDocument, options);
}
export type ConfirmHeartbeatMutationHookResult = ReturnType<typeof useConfirmHeartbeatMutation>;
export type ConfirmHeartbeatMutationResult = Apollo.MutationResult<Types.ConfirmHeartbeatMutation>;
export type ConfirmHeartbeatMutationOptions = Apollo.BaseMutationOptions<
    Types.ConfirmHeartbeatMutation,
    Types.ConfirmHeartbeatMutationVariables
>;
export const ConfirmIdentityDocument = gql`
    mutation ConfirmIdentity($identityConfirmInput: IdentityConfirmInput!) {
        confirmIdentity(identityConfirmInput: $identityConfirmInput) {
            confirmed
            groupId
        }
    }
`;
export type ConfirmIdentityMutationFn = Apollo.MutationFunction<
    Types.ConfirmIdentityMutation,
    Types.ConfirmIdentityMutationVariables
>;

/**
 * __useConfirmIdentityMutation__
 *
 * To run a mutation, you first call `useConfirmIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmIdentityMutation, { data, loading, error }] = useConfirmIdentityMutation({
 *   variables: {
 *      identityConfirmInput: // value for 'identityConfirmInput'
 *   },
 * });
 */
export function useConfirmIdentityMutation(
    baseOptions?: Apollo.MutationHookOptions<
        Types.ConfirmIdentityMutation,
        Types.ConfirmIdentityMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        Types.ConfirmIdentityMutation,
        Types.ConfirmIdentityMutationVariables
    >(ConfirmIdentityDocument, options);
}
export type ConfirmIdentityMutationHookResult = ReturnType<typeof useConfirmIdentityMutation>;
export type ConfirmIdentityMutationResult = Apollo.MutationResult<Types.ConfirmIdentityMutation>;
export type ConfirmIdentityMutationOptions = Apollo.BaseMutationOptions<
    Types.ConfirmIdentityMutation,
    Types.ConfirmIdentityMutationVariables
>;
