import { createContext } from 'react';

interface TopicContextType {
    user: string;
    setUser: React.Dispatch<string | undefined>;
    group: number;
    setGroup: React.Dispatch<number | undefined>;
}

const UserContext = createContext<TopicContextType | undefined>(undefined);

export default UserContext;
