import { Box, Button, FormInput, Pane, Text, useForm } from '@ti/ui-lib';
import React, { ReactElement, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import UserContext from '../../context/topicContext';
import { useConfirmIdentityMutation } from '../../queries/autogenerate/hooks';

const Home: React.FC = (): ReactElement => {
    const { setUser, setGroup } = useContext(UserContext);
    const history = useHistory();
    const { sessionId } = useParams<{ sessionId: string }>();
    const form = useForm({
        initialValues: {
            userKey: '',
        },
        validationSchema: yup.object().shape({
            userKey: yup
                .string()
                .required()
                .test(
                    'blank',
                    'Username cannot contain whitespace',
                    (value) => !value.includes(' '),
                ),
        }),
    });
    const [confirmIdentity] = useConfirmIdentityMutation();

    useEffect(() => {
        if (sessionId === undefined) {
            history.push('/error');
        }
    }, []);

    const handleEnterKeypress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitUsername();
        }
    };

    const handleEnterClick = () => {
        submitUsername();
    };

    //TODO: Validate username with sessionId in URL
    const submitUsername = async () => {
        try {
            const {
                data: {
                    confirmIdentity: { confirmed, groupId },
                },
            } = await confirmIdentity({
                variables: {
                    identityConfirmInput: {
                        userKey: form.values['userKey'],
                        hash: sessionId,
                    },
                },
            });

            if (confirmed) {
                setUser(form.values['userKey']);
                setGroup(groupId);

                history.push('/simulator');
            } else {
                history.push('/error');
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Pane flex flexGrow col>
            <Box alignSelf="center" width={500}>
                <Pane depth={3} light rounded flex col>
                    <Box>
                        <Text>Enter the key provided in the email:</Text>
                        <FormInput form={form} field="userKey" onKeyPress={handleEnterKeypress} />
                    </Box>
                    <Box flex flexGrow space="mt2" justifyContent="end">
                        <Button
                            primary
                            onClick={handleEnterClick}
                            disabled={form.errors.length > 0}
                        >
                            Enter
                        </Button>
                    </Box>
                </Pane>
            </Box>
        </Pane>
    );
};

export default Home;
