import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, Reference } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const httpLink = new HttpLink({
    uri: '/api/',
});

const wsProtocol = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
const wsUrl = `${wsProtocol}${window.location.host}/subscriptions/`;

const wsClient = new SubscriptionClient(wsUrl, {
    timeout: 61000,
    reconnect: true,
});

wsClient.onDisconnected((): void => {
    // toaster.danger({ message: 'Something went wrong. Please refresh Gari.', persist: true });
    // console.log('disconnected');
});

wsClient.onReconnecting((): void => {
    // console.log('reconnecting...');
});

wsClient.onReconnected((): void => {
    // console.log('reconnected');
    // toaster.clearAll();
});

const wsLink = new WebSocketLink(wsClient);

const splitLink = split(
    ({ query }): boolean => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
);

export const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    primerEvents: {
                        merge(_: Reference[], incoming: Reference[]): Reference[] {
                            return [...incoming];
                        },
                    },
                },
            },
            Topic: {
                fields: {
                    commentaries: {
                        keyArgs: [],
                        merge(existing: Reference[] = [], incoming: Reference[]): Reference[] {
                            return [...existing, ...incoming];
                        },
                    },
                    contactResponses: {
                        keyArgs: false,
                        merge(existing: Reference[] = [], incoming: Reference[]): Reference[] {
                            return [...existing, ...incoming];
                        },
                    },
                    overviews: {
                        keyArgs: false,
                        merge(existing: Reference[] = [], incoming: Reference[]): Reference[] {
                            return [...existing, ...incoming];
                        },
                    },
                    wasabiUpdates: {
                        keyArgs: false,
                        merge(existing: Reference[] = [], incoming: Reference[]): Reference[] {
                            return [...existing, ...incoming];
                        },
                    },
                },
            },
        },
    }),
    connectToDevTools: true,
});
